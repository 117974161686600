<template>
  <div class="qf">
    <div class="qf__sliders">
      <div class="qf__slider">
        <div class="qf__slider-label">Quality</div>
        <vue-slider
          class="qf__slider-progress"
          :disabled="disabled"
          :value="quality"
          :min="1"
          :max="5"
          :piecewise="true"
          :piecewiseLabel="true"
          width="100%"
          :tooltip="false"
          :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
          :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
          :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
          @callback="watchQuality"
        >
          <template slot="label" slot-scope="{ label, active }">
            <span v-if="label == 1" class="qf__slider-progress-label qf__slider-progress-label--left">Low</span>
            <span v-if="label == 3" class="qf__slider-progress-label qf__slider-progress-label--center">Mid</span>
            <span v-if="label == 5" class="qf__slider-progress-label qf__slider-progress-label--right">High</span>
          </template>
        </vue-slider>
      </div>
      <div class="qf__slider">
        <div class="qf__slider-label">Price</div>
        <vue-slider
          class="qf__slider-progress"
          :disabled="disabled"
          :value="price"
          :min="1"
          :max="5"
          :piecewise="true"
          width="100%"
          :tooltip="false"
          :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
          :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
          :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
          @callback="watchPrice"
        >
        </vue-slider>
      </div>
      <div class="qf__slider">
        <div class="qf__slider-label">Speed</div>
        <vue-slider
          class="qf__slider-progress"
          :disabled="disabled"
          :value="speed"
          :min="1"
          :max="5"
          :piecewise="true"
          width="100%"
          :tooltip="false"
          :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
          :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
          :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
          @callback="watchSpeed"
        >
        </vue-slider>
      </div>
    </div>
    <div class="qf__diagram">
      <div class="qf__diagram-container">
        <svg viewBox="0 0 240 240" class="qf__diagram-figure">
          <polygon class="qf__diagram-border" points="120,0 240,205 0,205" />
          <polygon class="qf__diagram-triangle" :points="trianglePoints" />
          <g class="qf__diagram-grid">
            <line x1="0" y1="205" x2="120" y2="137" />
            <line x1="240" y1="205" x2="120" y2="137" />
            <line x1="120" y1="0" x2="120" y2="137" />
          </g>
        </svg>
        <span class="qf__diagram-label qf__diagram-label--top">Price</span>
        <span class="qf__diagram-label qf__diagram-label--left">Quality</span>
        <span class="qf__diagram-label qf__diagram-label--right">Speed</span>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component/src/vue2-slider.vue';
export default {
  components: { VueSlider },
  props: ['quality', 'price', 'speed', 'disabled'],
  data() {
    return {
      trianglePoints: null,
    };
  },
  computed: {
    focus() {
      return {
        quality: this.quality,
        price: this.price,
        speed: this.speed,
      };
    },
  },
  methods: {
    watchQuality(val) {
      this.adjustSliders(0, val - this.quality);
      this.drawTriangle();
    },
    watchPrice(val) {
      this.adjustSliders(1, val - this.price);
      this.drawTriangle();
    },
    watchSpeed(val) {
      this.adjustSliders(2, val - this.speed);
      this.drawTriangle();
    },
    getNextSlider(slider) {
      if (slider + 1 > 2) {
        return 0;
      } else {
        return slider + 1;
      }
    },
    adjustSliders(slider, change) {
      const sliders = ['quality', 'price', 'speed'];
      const current = slider;
      const next = this.getNextSlider(slider);
      const nextAfterThat = this.getNextSlider(next);

      this[sliders[current]] += change;

      if (this[sliders[next]] - change < 1) {
        this[sliders[nextAfterThat]] -= change;
      } else if (this[sliders[next]] - change > 5) {
        this[sliders[nextAfterThat]] -= change;
      } else {
        this[sliders[next]] -= change;
      }

      this.$emit('change', this.focus);
    },
    drawTriangle() {
      const step = 137 / 5;
      const top = 5 - this.price;
      const left = 5 - this.quality;
      const right = 5 - this.speed;
      this.trianglePoints = `120,${step * top}
              ${Math.sin(Math.PI / 3) * left * step},${Math.cos(Math.PI / 3) * -left * step + 205}
              ${Math.sin(Math.PI / 3) * -right * step + 240},${Math.cos(Math.PI / 3) * -right * step + 205}`;
    },
  },
  mounted() {
    this.drawTriangle();
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.qf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: math.div(3rem, 1.6);
  flex-wrap: wrap;
  @include mobile-ls-min {
    margin-top: math.div(0.5rem, 1.6);
    flex-wrap: nowrap;
  }
  &__sliders {
    width: 100%;
    @include mobile-ls-min {
      width: 60%;
    }
  }
  &__slider {
    display: flex;
    padding: math.div(0.5rem, 1.6) 0;
    align-items: center;
    &-label {
      width: math.div(6rem, 1.6);
      flex-shrink: 0;
      font-size: math.div(1.2rem, 1.6);
      text-transform: uppercase;
    }
    &-progress {
      &.vue-slider-component.vue-slider-has-label {
        margin-bottom: 0;
      }
      &-label {
        position: absolute;
        top: math.div(-3rem, 1.6);
        left: 0;
        font-size: math.div(1.1rem, 1.6);
        text-transform: uppercase;
        &--center {
          transform: translateX(-50%);
          margin-left: 3px;
        }
        &--right {
          transform: translateX(-100%);
          margin-left: 3px;
        }
      }
    }
  }
  &__diagram {
    width: 100%;
    text-align: center;
    @include mobile-ls-min {
      width: 40%;
    }
    &-container {
      position: relative;
      display: inline-block;
      max-width: math.div(15rem, 1.6);
      margin: 0 auto;
      padding-top: math.div(2.5rem, 1.6);
      @include mobile-ls-min {
        max-width: calc(100% - #{math.div(3rem, 1.6)});
      }
    }
    &-figure {
      display: block;
      width: 100%;
      max-width: math.div(24rem, 1.6);
      height: auto;
    }
    &-border {
      fill: none;
      stroke: rgba($c-dark, 0.2);
      stroke-width: 1;
    }
    &-grid {
      stroke: rgba($c-dark, 0.2);
      stroke-width: 1;
    }
    &-triangle {
      fill: $c-blue;
    }
    &-label {
      position: absolute;
      font-size: math.div(1.2rem, 1.6);
      text-transform: uppercase;
      &--top {
        top: math.div(0.5rem, 1.6);
        left: 50%;
        transform: translateX(-50%);
      }
      &--left {
        bottom: math.div(0.5rem, 1.6);
        left: math.div(-1rem, 1.6);
      }
      &--right {
        bottom: math.div(0.5rem, 1.6);
        right: math.div(-1rem, 1.6);
      }
    }
  }
}
</style>
