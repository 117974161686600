var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "qf" }, [
    _c("div", { staticClass: "qf__sliders" }, [
      _c(
        "div",
        { staticClass: "qf__slider" },
        [
          _c("div", { staticClass: "qf__slider-label" }, [_vm._v("Quality")]),
          _vm._v(" "),
          _c("vue-slider", {
            staticClass: "qf__slider-progress",
            attrs: {
              disabled: _vm.disabled,
              value: _vm.quality,
              min: 1,
              max: 5,
              piecewise: true,
              piecewiseLabel: true,
              width: "100%",
              tooltip: false,
              bgStyle: { backgroundColor: "rgba(55, 73, 94, 0.13)" },
              sliderStyle: {
                backgroundColor: "#fff",
                border: "2px solid #37495e",
              },
              processStyle: { backgroundColor: "rgba(55, 73, 94, 0.8)" },
            },
            on: { callback: _vm.watchQuality },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function (ref) {
                  var label = ref.label
                  var active = ref.active
                  return [
                    label == 1
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "qf__slider-progress-label qf__slider-progress-label--left",
                          },
                          [_vm._v("Low")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    label == 3
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "qf__slider-progress-label qf__slider-progress-label--center",
                          },
                          [_vm._v("Mid")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    label == 5
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "qf__slider-progress-label qf__slider-progress-label--right",
                          },
                          [_vm._v("High")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "qf__slider" },
        [
          _c("div", { staticClass: "qf__slider-label" }, [_vm._v("Price")]),
          _vm._v(" "),
          _c("vue-slider", {
            staticClass: "qf__slider-progress",
            attrs: {
              disabled: _vm.disabled,
              value: _vm.price,
              min: 1,
              max: 5,
              piecewise: true,
              width: "100%",
              tooltip: false,
              bgStyle: { backgroundColor: "rgba(55, 73, 94, 0.13)" },
              sliderStyle: {
                backgroundColor: "#fff",
                border: "2px solid #37495e",
              },
              processStyle: { backgroundColor: "rgba(55, 73, 94, 0.8)" },
            },
            on: { callback: _vm.watchPrice },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "qf__slider" },
        [
          _c("div", { staticClass: "qf__slider-label" }, [_vm._v("Speed")]),
          _vm._v(" "),
          _c("vue-slider", {
            staticClass: "qf__slider-progress",
            attrs: {
              disabled: _vm.disabled,
              value: _vm.speed,
              min: 1,
              max: 5,
              piecewise: true,
              width: "100%",
              tooltip: false,
              bgStyle: { backgroundColor: "rgba(55, 73, 94, 0.13)" },
              sliderStyle: {
                backgroundColor: "#fff",
                border: "2px solid #37495e",
              },
              processStyle: { backgroundColor: "rgba(55, 73, 94, 0.8)" },
            },
            on: { callback: _vm.watchSpeed },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "qf__diagram" }, [
      _c("div", { staticClass: "qf__diagram-container" }, [
        _c(
          "svg",
          {
            staticClass: "qf__diagram-figure",
            attrs: { viewBox: "0 0 240 240" },
          },
          [
            _c("polygon", {
              staticClass: "qf__diagram-border",
              attrs: { points: "120,0 240,205 0,205" },
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "qf__diagram-triangle",
              attrs: { points: _vm.trianglePoints },
            }),
            _vm._v(" "),
            _c("g", { staticClass: "qf__diagram-grid" }, [
              _c("line", {
                attrs: { x1: "0", y1: "205", x2: "120", y2: "137" },
              }),
              _vm._v(" "),
              _c("line", {
                attrs: { x1: "240", y1: "205", x2: "120", y2: "137" },
              }),
              _vm._v(" "),
              _c("line", {
                attrs: { x1: "120", y1: "0", x2: "120", y2: "137" },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "qf__diagram-label qf__diagram-label--top" },
          [_vm._v("Price")]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "qf__diagram-label qf__diagram-label--left" },
          [_vm._v("Quality")]
        ),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "qf__diagram-label qf__diagram-label--right" },
          [_vm._v("Speed")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }